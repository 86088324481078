import { BodyText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { useIcon } from 'hooks/useContentful';
import { makeStyles } from 'lib/makeStyles';
import { memo } from 'react';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

export type FeatureBulletProps = {
  as?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  entry: SerializedComponentItem;
  dark?: boolean;
  hasBluePrimaryText?: boolean;
} & ParentClassNameProp;

const useStyles = makeStyles<Omit<FeatureBulletProps, 'entry'>>()({
  wrapper: (props) => ['py-4', props?.className],
  icon: (props: FeatureBulletProps) => [
    'w-8 h-8',
    {
      'text-white': props.dark
    },
    {
      'text-primaryText': !props.dark
    }
  ],
  primaryText: (props: FeatureBulletProps) => [
    'text-base font-semibold',
    'mt-1',
    {
      'text-primaryText': !props.dark && !props.hasBluePrimaryText
    },
    {
      'text-tmBlue': props.hasBluePrimaryText
    },
    {
      'text-white': props.dark && !props.hasBluePrimaryText
    }
  ],
  secondaryText: (props: FeatureBulletProps) => [
    'text-base',
    {
      'text-secondaryText': !props.dark
    },
    {
      'text-secondaryTextDark': props.dark
    }
  ]
});

const FeatureBullet: React.FC<FeatureBulletProps> = memo(
  ({ entry, dark = false, className, hasBluePrimaryText = false, as = 'h5' }) => {
    const icon = entry.icon;
    const Icon = icon && useIcon(icon);
    const styles = useStyles({ dark, className, hasBluePrimaryText });
    const WrapperComponent = as;

    return (
      <div className={styles.wrapper}>
        <PanelItem align="start">
          {icon && (
            <div>
              <Icon className={styles.icon} />
            </div>
          )}
          <PanelItem column align="start" paddingY={false}>
            <WrapperComponent className={styles.primaryText}>{entry.primaryText}</WrapperComponent>
            <BodyText disableColorStyle className={styles.secondaryText}>
              {entry.secondaryText}
            </BodyText>
          </PanelItem>
        </PanelItem>
      </div>
    );
  }
);

FeatureBullet.displayName = 'FeatureBullet';

export { FeatureBullet };
